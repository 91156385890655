
import localforage from 'localforage'
import { defineComponent, onUnmounted } from 'vue'

export default defineComponent({
    setup() {
        onUnmounted(() => {
            localforage.removeItem('routeDetailsId')
        })
        return {}
    },
})
